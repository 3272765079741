import {
  CUSTOM_OFFSET_EXTRA_WIDTH,
  DEFAULT_OFFSET_EXTRA_WIDTH,
  LARGE_WIDGET_TOTAL_WIDTH,
  MOBILE_WIDTH_BREAKPOINT,
  SMALL_HEIGHT_BREAKPOINT,
} from './constants';

export const wait = (ms: number) => {
  return new Promise(res => setTimeout(res, ms));
};

export async function callWithRetry<T>(
  fn: () => T,
  depth = 0,
): Promise<T | null> {
  const ret = fn();
  if (ret) return ret;
  else {
    if (depth > 4) return null;
    await wait(2 ** depth * 600);
    return callWithRetry(fn, depth + 1);
  }
}

export const mobileWidthCondition = window.matchMedia(
  `(max-width: ${MOBILE_WIDTH_BREAKPOINT}px)`,
);
export const getDynamicWidthCondition = () => {
  const doc = window.document;
  const currentScript = doc.currentScript;

  if (!currentScript) throw new Error('FT: Could not find embed script');

  const offsetX = currentScript.getAttribute('offset-x') || '0px';
  const margin =
    offsetX === '0px'
      ? `${DEFAULT_OFFSET_EXTRA_WIDTH}px`
      : `${CUSTOM_OFFSET_EXTRA_WIDTH}px`;

  return window.matchMedia(
    `(min-width: ${MOBILE_WIDTH_BREAKPOINT + 1}px) and (max-width: calc(${LARGE_WIDGET_TOTAL_WIDTH}px + ${offsetX} - ${margin}))`,
  );
};
export const smallHeightCondition = window.matchMedia(
  `(max-height: ${SMALL_HEIGHT_BREAKPOINT}px)`,
);

/**
 * Calls a function with retries every `intervalMs` milliseconds,
 * up to `numRetries` times, after an initial delay of `initialDelayMs`.
 * Collects and returns the first successful return value or throws if all attempts fail.
 *
 * @param callback - The function to be called.
 * @param intervalMs - Time in milliseconds between retries.
 * @param numRetries - Maximum number of retries.
 * @param initialDelayMs - Time in milliseconds to wait before the first attempt.
 * @returns A Promise that resolves with the callback's return value or rejects if all attempts fail.
 */
export async function retryFunction<T>({
  callback,
  initialDelayMs = 0,
  intervalMs = 1000,
  numRetries = 3,
}: {
  callback: () => T | Promise<T>;
  initialDelayMs?: number;
  intervalMs?: number;
  numRetries?: number;
}): Promise<T> {
  return new Promise((resolve, reject) => {
    let attempt = 0;

    const tryCallback = async () => {
      try {
        const result = await callback();
        resolve(result);
      } catch (error) {
        attempt++;
        if (attempt <= numRetries) {
          // eslint-disable-next-line no-console -- needed
          console.log('retryFunction failed, attempting retry:', error);
          setTimeout(tryCallback, intervalMs);
        } else {
          console.error('retryFunction failed:', error);
          reject(error);
        }
      }
    };

    setTimeout(async () => {
      await tryCallback();
    }, initialDelayMs);
  });
}
