import { WIDGET_TOTAL_MAX_HEIGHT } from './constants';
import state from './state';

const doc = window.document;
const currentScript = doc.currentScript;
if (!currentScript) throw new Error('FT: Could not find embed script');

const position = currentScript.getAttribute('position');
const positionX = currentScript.getAttribute('position-x');
const positionY = currentScript.getAttribute('position-y');
const offsetX = currentScript.getAttribute('offset-x') || '';
const offsetY = currentScript.getAttribute('offset-y') || '';

// Reading this regex:
// <start of input><then there can be a negative sign><then one or more numbers><then either 'px' or 'rem' or nothing><end of input>
const offsetRegex = /^[-]?\d+(px|rem)$/;
const validatedOffsetXOpened = offsetRegex.test(offsetX)
  ? `calc(${offsetX} - 28px)`
  : '0';
const validatedOffsetYOpened = offsetRegex.test(offsetY)
  ? `calc(${offsetY} - 28px)`
  : '0';

const getPosX = () =>
  position === 'left' ||
  positionX === 'left' ||
  state.placement === 'bottom_left'
    ? 'left'
    : 'right';
const getPosXOpposite = () => (getPosX() === 'left' ? 'right' : 'left');
const posY = positionY === 'top' ? 'top' : 'bottom';
const posYOpposite = posY === 'top' ? 'bottom' : 'top';

/**
 * Because we are not overriding styles that were previously set unless
 * declared here we need to make sure anytime we go back to defaults we
 * set those styles to empty strings `''` so they are not applied.
 *
 * posXOpposite and posYOpposite are needed because all inset values
 * get set to 0 when open but when closed we only want posX and posY
 * to be set.
 */
const getDefaultIframeStyles = () =>
  ({
    border: 'none',
    colorScheme: 'normal',
    height: '0',
    maxHeight: `${WIDGET_TOTAL_MAX_HEIGHT}px`,
    overflow: 'hidden',
    position: 'fixed',
    [getPosX()]: validatedOffsetXOpened,
    [getPosXOpposite()]: '',
    [posY]: validatedOffsetYOpened,
    [posYOpposite]: '',
    webkitMaskImage: '-webkit-radial-gradient(white, black)',
    width: '0',
    zIndex: '999999',

    ...(state.launchStyle === 'floating_bar'
      ? {
          left: '0',
          marginLeft: 'auto',
          marginRight: 'auto',
          right: '0',
        }
      : {}),
  }) as const satisfies Partial<CSSStyleDeclaration>;

export function setIframeStylesWithDefaults(
  iframe: HTMLIFrameElement,
  styles?: Partial<CSSStyleDeclaration>,
) {
  Object.entries({ ...getDefaultIframeStyles(), ...styles }).forEach(
    ([property, value]) => {
      // @ts-expect-error `Object.entries()` looses proper type information for `property`
      iframe.style[property] = value;
    },
  );
}

export function setOpenResponsiveStyles(iframe: HTMLIFrameElement) {
  setIframeStylesWithDefaults(iframe, {
    bottom: '0',
    height: '100%',
    left: '0',
    maxHeight: 'initial',
    right: '0',
    top: '0',
    width: '100%',
  } as const satisfies Partial<CSSStyleDeclaration>);
}

export function setBuilderOpenStyles(
  iframe: HTMLIFrameElement,
  styles?: Partial<CSSStyleDeclaration>,
) {
  setIframeStylesWithDefaults(iframe, {
    borderRadius: '12px 0 0 12px',
    bottom: '0',
    height: 'calc(100% - 69px)',
    maxHeight: 'initial',
    right: '0',
    width: '100%',
    zIndex: '1199',

    ...styles,
  } as const satisfies Partial<CSSStyleDeclaration>);
}
